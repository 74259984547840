<template>
  <div class="master-login-container">
    <img src="@/assets/logo.png" alt="" class="logo">
    <div class="login-form">
      <form v-on:submit.prevent="getLogin">
        <h1>관리자 로그인</h1>
        <div class="label-box">
          <label>
            아이디
          </label>
          <input type="text" placeholder="아이디 입력" class="custom-input-box" v-model="email">
        </div>
        <div class="label-box">
          <label>
            패스워드
          </label>
          <input type="password" placeholder="패스워드 입력" class="custom-input-box" v-model="password" v-on:keypress.enter.prevent="getLogin" autoComplete="off">
        </div>
        <button class="custom-btn-box">로그인</button>
      </form>
    </div>
    <button class="master-create custom-btn-box" @click="signOut">로그아웃</button>
<!--    <button class="master-create custom-btn-box" @click="createMaster">관리자 아이디 생성</button>-->
    <p class="footer-title">Copyrights (C)2023 JICODE. All Rights Reserved.</p>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>
import {isValidEmail, isValidPassword} from "@/lib/validation";
import {auth, Timestamp, firestore} from "@/firebase/firebaseConfig";
import Progress from "@/components/Progress";
import {firebaseError} from "@/firebase/firebaseError";
import {signOut} from "@/firebase/firebaseAuth";

export default {
  name: "MasterLogin",
  components: {Progress},
  data() {
    return {
      fbCollection: 'master',
      email: '',
      password: '',
      isProgress: false,
      lastLog: '',
    }
  },
  methods: {
    validateInput() {
      if (this.email.trim().length === 0) {
        return '이메일을 입력하세요.';
      }
      if (this.password.trim().length === 0) {
        return '비밀번호를 입력하세요.';
      }
      if (!isValidEmail(this.email)) {
        return '올바른 이메일 형식을 입력하세요.';
      }
      if (!isValidPassword(this.password)) {
        return '비밀번호는 영어숫자 조합 8자리 이상이어야 합니다.';
      }
      return null;
    },
    async checkDouble() {
      const self = this;
      const querySnapshot = await firestore.collection('master')
          .where('email', '==', self.email)
          .get();
      if (querySnapshot.size !== 0) { // 마스터
        return true;
      } else {
        self.$swal.fire({title: '등록된 아이디 없음', text: '등록된 아이디가 없습니다.', icon: 'error'})
        return false;
      }
    },
    async getLogin() {
      const self = this;

      const errorMessage = this.validateInput();
      if (errorMessage) {
        self.$swal.fire(errorMessage);
        return;
      }
      if (!(await this.checkDouble())) {
        return;
      }
      this.isProgress = true;

      try {
        const result = await auth.signInWithEmailAndPassword(this.email, this.password);
        self.isProgress = false;
        await this.setLog(result.user.uid);
      } catch (e) {
        self.isProgress = false;
        self.$swal.fire({
          title: 'Login failed',
          text: `Error: ${e.message}`, // Show the specific error message
          icon: 'error'
        });
      }
    },
    async setLog(uid) {
      const self = this;
      const defRef = firestore.collection(self.fbCollection);

      const doc = await defRef.doc(uid).get();
      self.lastLog = doc.data().currentLog;
      await defRef.doc(uid).update({
        lastLog: self.lastLog,
        currentLog: Timestamp.now()
      });

      self.isProgress = false;
      localStorage.setItem('userRole','master');
      await self.$router.replace('/master/main');
    },
    createMaster() {
      const self = this;
      const id = 'master@lamacat.com'
      const password = '1q2w3e4r'

      const _masterInfo = {
        email: 'master@lamacat.com',
        mobile: '010-1234-1234',
        userName: "시스템관리자",
        userRole: "master",
        currentLog: Timestamp.now(),
        lastLog: Timestamp.now(),
      }

      auth.createUserWithEmailAndPassword(id, password).then(async (userCredential) => {
        let user = userCredential.user;
        await user.updateProfile({displayName: '관리자'})
        await auth.signOut();
        const uid = user.uid;

        await firestore.collection('master').doc(uid).set(_masterInfo)
            .then(async () => {
              self.isProgress = false;
              self.$swal.fire({title: '등록 완료', text: '관리자 생성이 완료되었습니다.', icon: 'success'})
              await self.$router.push({name: 'MasterLogin'})
            })
            .catch((err) => {
              // 실패 후 실행할 코드
              self.isProgress = false;
              firebaseError(err);
            })
      })
    },
    signOut,
  },

}
</script>

<style scoped>
.master-login-container {
  font-weight: 500;
  background: #FFFFFF; /* 배경색 변경 */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo {
  width: 150px; /* 로고 크기 조절 */
  margin-bottom: 40px;
}

.master-login-container .login-form {
  margin: 0 auto;
  width: 418px;
  height: 376px;
  background: #F5F5F5; /* 카드 배경색 변경 */
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04); /* 그림자 추가 */
  border-radius: 8px;
  padding: 40px 20px;
}

.master-login-container .login-form h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.master-login-container .label-box label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272833;
  text-align: left !important;
}

.master-login-container .label-box {
  text-align: left;
  margin-top: 20px;
}

.master-login-container .label-box input {
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  margin-bottom: 0;
}

.master-login-container .master-create {
  width: 300px;
}

.master-login-container .custom-btn-box {
  margin: 40px 0;
  background: #4C9AF5;
}

.master-login-container .footer-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7F75A1;
  margin-top: 40px;
}

.custom-input-box {
  padding: 12px 8px;
  height: 40px;
  background: #FFFFFF;
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
}

.custom-input-box::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}


.custom-btn-box {
  width: 100%;
  height: 40px;
  background: #4C9AF5;
  border: 0;
  font-family: 'Noto Sans KR', serif;
  font-weight: 700;
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

</style>